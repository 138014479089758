body, html {
  padding: 0px;
  margin: 0px;
  font-family: -apple-system,
  BlinkMacSystemFont,
  Roboto,
  Open Sans,
  Helvetica Neue,
  sans-serif;
  font-size: 13px;
  line-height: 1.2;
  font-weight: 400;
}

h1, h2, h3{
  padding: 0;
  margin: 0;
  font-size: 20px;
}

a{
  text-decoration: none;
  color: black;
}

p{
  margin: 0;
  padding: 0;
}

ul, ol {
  margin: 0;
}

input, textarea{
  padding: 10px;
  border: 1px solid #d8d8d8;
  outline: none;
  color: black;
  border-radius: 5px;
}

select{
  padding: 14px 10px;
  border: 1px solid #aec4dc;
  outline: none;
  color: black;
  border-radius: 5px;
}

.root{
  min-height: 100vh;
}

.centered{
  max-width: 1170px;
  height: 100%;
  margin: 0 auto;
}

.page{
  background: #e6eff7;
  box-shadow: 5px 5px 5px #888888;
  padding-bottom: 50px;
}

.page h1{
  text-align: center;
  padding: 40px 20px 20px;
  font-size: 38px;
  color: #505050;
}

.page h2{
  margin-top: 30px;
  margin-bottom: 30px;
  color: #053d6c;
  font-size: 30px;
  font-weight: 700;
}

.whitePage{
  padding: 20px;
  background: white;
  width: 780px;
  margin: 0 auto;
  border: 1px solid #d8d8d8;
  box-shadow: 7px 7px 7px #848484
}

.button{
  text-align: center;
  background: #0e74ca;
  text-transform: uppercase;
  color: white;
  padding: 10px 50px 8px;
  border-radius: 31px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 2px 2px 2px #053d6c;
  cursor: pointer;
}

.button:hover{
  opacity: 0.9;
}

.button:active{
  padding: 11px 50px 7px;
}

.launchButton{
  background: #FFFFFF;
  text-transform: uppercase;
  color: black;
  padding: 10px 50px 8px;
  border-radius: 31px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 2px 2px 2px #040421;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
}

.launchButton img{
  height: 30px;
}

.launchButton span{
  color: #525252;
  margin: 2px 0 0px 15px;
}

/* Кастомный чекбокс и радиобутон */
.checkboxLabel{
  display: flex;
  flex-direction: inherit;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.checkboxLabel:hover .checkboxCustom::before{
  content: '';
  width: 7px;
  height: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: solid #cce0f7;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translate3d(-6px, -2px, 0);
}

.checkboxNonDisplayd {
  display: none;
}
.checkboxCustom {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #326398;
  border-radius: 5px;
  margin-right: 10px;
}

.checkboxNonDisplayd:checked + .checkboxCustom::before {
  content: '';
  width: 7px;
  height: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: solid #326398;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translate3d(-6px, -2px, 0);
}

.radioLabel{
  display: flex;
  flex-direction: inherit;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  color: #326398;
}

.radioLabel:hover .radioCustom::before{
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background: #cce0f7;
  border-radius: 50%;
}

.radioNonDisplayd {
  display: none;
}

.radioCustom {
  width: 20px;
  height: 20px;
  border: 2px solid #326398;
  position: relative;
  margin-right: 10px;
  border-radius: 50%;
}

.radioNonDisplayd:checked + .radioCustom::before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background: #326398;
  border-radius: 50%;
}
/* //Кастомный чекбокс и радиобутон */

@media screen and (max-width: 820px) {
  .whitePage{
    width: unset;
  }
}