.popupBackground{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000cf;
    z-index: 10000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.closePopup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.buttonClosePopup{
    font-size: 30px;
    margin-right: 15px;
    cursor: pointer;
}

.buttonClosePopup:hover{
    color: #178fe8;
}

.popup{
    max-height: 90vh;
    overflow: auto;
    z-index: 1001;
    border-radius: 10px;
    background: white;
    box-shadow: 10px 10px 30px black;
    padding-top: 10px;
}

.popupTop{
    display: flex;
}

.left{
    width: 293px;
}

.cupon{
    background: #d6f9d1;
    border: 2px solid #8ede84;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
}

.currentCupon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.currentCupon span{
    font-size: 14px;
    font-weight: 700;
}

.onEveryAnswerCupon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.onEveryAnswerCupon span{
    color: #00d012;
    font-size: 14px;
    font-weight: 700;
}

.step{
    text-align: center;
    font-size: 20px;
    color: #676767;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.step span{
    color: #178fe8;
    display: block;
    font-weight: 700;
    margin: -2px 5px 0;
}

.consultant{

}

.rules{
    padding: 20px 20px;
    font-size: 12px;
    color: #949494;
    line-height: 25px;
}

.rules a{
    color: #178fe8;
    text-decoration: underline;
}

.rules a:hover{
    text-decoration: none;
}

.right{
    width: 500px;
}

.h1TitleAndCloseButton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.h1Title{
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.h2Title{
    color: #525252;
    font-size: 16px;
    margin-bottom: 34px;
    line-height: 20px;
}

.questionBlock{

}

.stepAndQuestionInQuestionBlock{
    display: flex;
    font-size: 16px;
    font-weight: 700;
}

.stepInQuestionBlock{
    color: #178fe8;
    margin-right: 5px;
}

.questionInQuestionBlock{

}

.answersBlock{
    margin-top: 40px;
    min-height: 396px;
}

.answers{
    display: flex;
    background: #eee;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.answerGalochka{
    background: white;
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #a9a9a9;
    font-size: 22px;
    color: #a9a9a9;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.answerText{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.answerText span{
    border-bottom: 1px dotted #5a5a5a;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.answers:hover {
    box-shadow: 3px 3px 5px #848484;
    background: whitesmoke;
}

.answers:hover .answerGalochka{
    border: 2px solid #178fe7;
    color: #178fe8;
}

.answers:hover .answerText span{
    color: #178fe8;
    border-bottom: 1px dotted #178fe8;
}

.answerGalochka:hover{
    background: #178fe8;
    color: white!important;
    box-shadow: 3px 3px 3px #00406f;
}

.answerText span:hover{
    border-bottom: 0!important;
}

.thanks{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thanksH1Title{
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
}

.thanksText{
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
}

.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactH1Title{
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;
    color: black;
    font-weight: 700;
}

.contactInput{
    margin-top: 20px;
    width: 300px;
}

.contactButton{
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    background: #0e74ca;
    text-transform: uppercase;
    color: white;
    padding: 17px 50px 15px;
    border-radius: 31px;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 2px 2px 2px #053d6c;
    cursor: pointer;
}

.contactButton:hover{
    opacity: 0.9;
}

.contactButton:active{
    padding: 19px 50px 13px;
}

.openButton {
    z-index: 500;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 30px;
    max-width: 450px;
    width: 100%;
    height: 58px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
    background: #0e74ca;
    background: linear-gradient(to top, #0061b3, #0e74ca);
    box-shadow: 0px 0px 10px #000000;
    -webkit-transition: ease 0.3s;
    transition: ease 0.3s;
}
.openButton:after, .contactButton:after {
    content: "";
    display: block;
    width: 30px;
    height: 300px;
    margin-left: 60px;
    background: #fff;
    background: -moz-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.7) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#b3ffffff',GradientType=0 );
    position: absolute;
    left: -40px;
    top: -150px;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    -webkit-animation-name: slideme;
    animation-name: slideme;
    -webkit-animation-duration: 7s;
    animation-duration: 7s;
    -webkit-animation-delay: 0.05s;
    animation-delay: 0.05s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes slideme {
    0% { left: -30px; margin-left: 0px; }
    30% { left: 110%; margin-left: 80px; }
    100% { left: 110%; margin-left: 80px; }
}

.openButtonH1Title{
    padding: 10px 21px 0;
    font-weight: 400;
}

.openButtonH2Title{
    font-size: 14px;
    padding: 5px 0px 0;
    text-align: center;
}

.popupFooter{
    background: #171717;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 5px solid #171717;
}

.popupFooter img{
    height: 90px;
    margin-right: 20px;
}

@media screen and (max-width: 770px) {
    .popup{
        max-height: 100vh;
        width: 100%;
        border-radius: 0;
    }
    .popupTop{
        display: block;
    }
    .left{
        width: 100%;
    }
    .right{
        width: 100%;
    }
    .consultant{
        display: none;
    }
    .popupFooter{
        display: none;
    }
    .h1Title{
        padding-left: 20px;
        padding-top: 20px;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 22px;
    }
    .h2Title{
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .step{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        padding: 20px;
        justify-content: left;
        box-shadow: 0 0 3px #949494;
    }
    .stepAndQuestionInQuestionBlock{
        padding: 20px 20px 0;
    }
    .questionBlock{
        margin-bottom: 90px;
    }
    .contactButton{
        margin-bottom: 20px;
        background: #ca0e0e;
    }
}

@media screen and (max-width: 470px) {
    .openButton{
        bottom: 0;
        left: 0;
        right: 0;
        max-width: unset;
        border-radius: 0;
        height: 75px;
    }
    .openButtonH2Title{
        text-align: left;
        padding: 5px 18px 0;
    }
    .contactButton {
        font-size: 13px;
        padding: 15px;
    }
}