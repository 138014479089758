.loadingTemplate{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading{
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.empty{
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image img{
  height: 45px;
  width: 45px;
  margin-right: 20px;
}

.text{
  color: #8c8c8c;
  font-size: 18px;
  font-weight: 700;
}