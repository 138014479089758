.dogovor{
    width: 800px;
    margin: 0 auto;
}

.inDogovor{
    padding: 30px;
    background: white;
    border: 1px solid #d8d8d8;
}

.h2{
    text-align: center;
    color: #053d6c;
    padding: 20px;
    font-weight: 700;
    font-size: 16px;
}

@media screen and (max-width: 800px) {
    .h1{
        font-size: 20px!important;
    }
    .dogovor{
        width: 100%;
    }
}