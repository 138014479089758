.footer{
    background: #171717;
    color: white;
    padding-bottom: 50px;
}

.top{
    border-bottom: 1px solid #d8d8d8;
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerLogo{

}

.footerLogo img{
    height: 120px;
    margin-top: -25px;
}

.phone{
    font-size: 14px;
}

.phone a{
    font-size: 28px;
    color: white;
    font-weight: 700;

}

.links{

}

.links ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.links ul li{
    padding: 6px;
}

.links ul li a{
    color: white;
    font-weight: 700;
}

.links ul li a:hover{
    text-decoration: underline;
}

.soc{

}

.soc img{
    height: 30px;
    padding: 10px 15px 0 0;
}

.socTitle{
    font-weight: 700;
    margin-top: 7px;
}

.bottom{
    padding: 40px 0;
    display: flex;
}

.bottomDescr{
    width: 45%;
    margin-right: 10%;
}

.partners{
    width: 45%;
}

.partners img{
    height: 30px;
    margin: 0 20px;
}

.partnersTitle{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}

@media screen and (max-width: 1170px) {
    .top{
        padding: 20px;
    }
    .bottom{
        padding: 20px;
    }
}

@media screen and (max-width: 1036px) {
    .top{
        display: block;
        text-align: center;
    }
    .bottom{
        display: block;
        text-align: center;
    }
    .bottomDescr{
        width: unset;
        margin-right: unset;
        margin-bottom: 50px;
    }
    .partners{
        width: unset;
    }
    .links{
        padding: 20px 0 40px;
    }
}
