.flex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.info{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #053d6c;
    width: 540px;
}

.in{
    background: white;
    border: 1px solid #d8d8d8;
    padding: 40px 20px;
}

.dopInfo{
    width: 540px;
}

.dopInfo img{
    margin: 20px 0;
    width: 100%;
}

.dopInfoTitle{
    text-align: center;
    color: #053d6c;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 700;
}

.dopInfoSubTitle{
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0;
}

.acceptLayout{
    text-align: center;
    margin-top: 40px;
}

.acceptButton{
    display: inline-block;
    background: #b32122;
    text-transform: uppercase;
    color: white;
    padding: 15px 80px 13px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 2px 2px 2px #610000;
    cursor: pointer;
}

.acceptButton:hover{
    opacity: 0.9;
    color: white;
}

.acceptButton:active{
    opacity: 0.8;
    color: white;
}

.link{
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
}

@media screen and (max-width: 1080px) {
    .flex{
        display: unset;
    }
    .info{
        margin: 20px auto 0;
    }
    .dopInfo{
        margin: 20px auto 0;
    }
}

@media screen and (max-width: 600px) {
    .info{
        width: 100%;
    }
    .dopInfo{
        width: 100%;
    }
    .acceptButton{
        font-size: 14px;
        padding: 15px 50px 13px;
    }
}