.resheniya {
    padding-top: 20px;
}

.resheniya a{
    font-size: 18px;
    color: #3f8600;
}

.resheniya a:hover{
    text-decoration: underline;
}

.resheniya a span{
    color: black;
    font-weight: 700;
}