.dogovorPage{

}

.dogovorPage h1{
    font-size: 30px!important;
    padding: 40px 20px!important;
}

.dogovor{
    width: 800px;
    margin: 0 auto;
}

.inDogovor{
    padding: 30px;
    background: white;
    border: 1px solid #d8d8d8;
}

.flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left{

}

.right{

}

.h2{
    text-align: center;
    color: #053d6c;
    padding: 20px;
    font-weight: 700;
    font-size: 16px;
}

.acceptLayout{
    text-align: center;
    margin: 50px 0;
}

.acceptButton{
    display: inline-block;
    background: #b32122;
    text-transform: uppercase;
    color: white;
    padding: 15px 80px 13px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 2px 2px 2px #610000;
    cursor: pointer;
}

.acceptButton:hover{
    opacity: 0.9;
}

.acceptButton:active{
    opacity: 0.8;
}

.table{
    width: 100%;
    border: 1px solid #d8d8d8;
}

.table tr{

}

.table tr td{
    border: 1px solid #d8d8d8;
    width: 50%;
    padding: 10px;
}

.table tr td img{
    height: 142px;
}

.form{
    width: 800px;
    margin: 0 auto;
}

.inForm{
    padding: 30px;
    background: white;
    border: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
}

.formField{
    margin-bottom: 30px;
}

.formFieldTitle{
    color: #053d6c;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
}

.formField input{
    width: 80%;
}

.formField span{
    padding-left: 10px;
}

@media screen and (max-width: 800px) {
    .dogovorPage h1{
        font-size: 20px!important;
    }
    .dogovor{
        width: 100%;
    }
    .form{
        width: 100%;
    }
}