.title{
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #053d6c;
    margin-top: 10px;
}

.block{
    width: 70%;
    margin: 40px auto;
}

.block span{
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 15px;
}

.block input{
    width: 90%;
}

.buttonBlock{
    text-align: center;
    margin: 30px 0 10px;
}

.button{
    display: inline-block;
    background: #0e74ca;
    text-transform: uppercase;
    color: white;
    padding: 10px 50px 8px;
    border-radius: 31px;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 2px 2px 2px #053d6c;
    cursor: pointer;
}

.button:hover{
    background: #0e74ca;
}

.button:active{
    padding: 11px 50px 7px;
}

.result{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.resultList{
    background: white;
    border: 1px solid #d8d8d8;
    width: 360px;
    margin: 0 9px;
    padding: 20px;
    box-shadow: 7px 7px 7px #848484
}

.resultTitle{
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #053d6c;
    margin: 10px;
}

.resulBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    font-size: 16px;
}

.resultBlockTitle{

}

.resultBlockDesc{
    font-weight: 700;
}

.extra{
    color: grey;
    text-align: center;
    margin-top: 20px;
}

.aftermath{
    margin-top: 30px;
    font-size: 16px;
}

.aftermath ul{

}

.aftermath ul li{
    margin-top: 20px;
    font-size: 14px;
}

@media screen and (max-width: 620px) {
    .result{
        display: block;
    }
    .resultList{
        width: 85%;
        margin: 0 auto;
    }
}